import React, { useState } from "react"

// Libraries
import styled from "styled-components"
import { transparentize } from "polished"
import { motion } from "framer-motion"

// Components
import Container from "components/container/"
import { SecondaryButtonLinkExternal } from "components/button/"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints/"

// Icons
import IconCheck from "assets/icons/icon-check.inline.svg"
import IconClose from "assets/icons/icon-close.inline.svg"
import IconCaret from "assets/icons/icon-caret-right.inline.svg"
import Separator from "components/separator"
import IconDownload from "assets/icons/options/arrow-download.inline.svg"

const StyledOptions = styled.section`
  padding: 110px 0;
  background: ${colors.gris};

  .options__title {
    text-align: center;

    .title {
      margin-top: 16px;
      margin-bottom: 60px;
    }
  }

  .options__tabs {
    .tab {
      padding-bottom: 16px;
      text-transform: uppercase;
      border-bottom: 4px solid transparent;
      letter-spacing: 0.05em;

      &--active {
        border-color: ${colors.magenta};
      }
    }
  }

  .plan {
    max-width: 225px;
    margin-left: auto;
    margin-right: auto;

    p {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }
  }

  .options {
    padding-bottom: 32px;
    border: 1px solid ${colors.azul};

    ${breakpoint.medium`
      padding: 0;
      border: 0;
      border-bottom: 1px solid ${transparentize(0.9, colors.azul)};
    `}

    .option {
      min-height: 60px;
      display: flex;
      align-items: center;
      padding: 16px 40px 16px 14px;
      border-top: 1px solid ${transparentize(0.9, colors.azul)};

      ${breakpoint.small`
        padding-right: 0;      
      `}

      ${breakpoint.medium`
        padding-left: 0;
        padding-right: 0;
      `}

      .row {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 0;
      }

      .name {
        max-width: 214px;
        font-size: 14px;
        line-height: 18px;

        ${breakpoint.medium`
          padding-left: 58px;
        `}
      }

      .column {
        display: none;

        &.column--active {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        ${breakpoint.medium`
          display: flex !important;
          align-items: center;
          justify-content: center;
        `}
      }
    }
  }

  .options__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -28px;

    .control {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.control--prev {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .options__button {
    margin-top: 40px;

    ${breakpoint.medium`
      margin-top: 64px;
    `}
  }
`

const Options = () => {
  const plans = ["standard", "advanced", "genome"]

  const [activeOption, setActiveOption] = useState(plans[0])

  const options = [
    {
      name: `<b>Síndromes más frecuentes:</b> S. Down, S. Edwards, S. Patau`,
      standard: true,
      advanced: true,
      genome: true,
    },
    {
      name: `<b>Anomalías de cromosomas sexuales</b>`,
      standard: true,
      advanced: true,
      genome: true,
    },
    {
      name: `<b>Anomalías relacionadas con pérdidas gestacionales</b>`,
      standard: false,
      advanced: true,
      genome: true,
    },
    {
      name: `<b>Microdeleciones más frecuentes </b>`,
      standard: false,
      advanced: true,
      genome: true,
    },
    {
      name: `Síndrome Jacobsen`,
      standard: false,
      advanced: true,
      genome: true,
    },
    {
      name: `S. Langer Giedion`,
      standard: false,
      advanced: true,
      genome: true,
    },
    {
      name: `<b>Aneuplodias en todos los cromosomas</b>`,
      standard: false,
      advanced: false,
      genome: true,
    },
    {
      name: `<b>Ganancias y pérdidas del material cromosómico > 7Mb</b>`,
      standard: false,
      advanced: false,
      genome: true,
    },
  ]

  const handlePrev = () => {
    if (plans.indexOf(activeOption) > 0) {
      setActiveOption(plans[plans.indexOf(activeOption) - 1])
    } else {
      setActiveOption(plans[plans.length - 1])
    }
  }

  const handleNext = () => {
    if (plans.indexOf(activeOption) < plans.length - 1) {
      setActiveOption(plans[plans.indexOf(activeOption) + 1])
    } else {
      setActiveOption(plans[0])
    }
  }

  return (
    <StyledOptions id="tipos-de-tests">
      <Container>
        <div className="options__title">
          <Separator colors={[colors.magenta, colors.azul]} />
          <h2 className="title">Tipos de test</h2>
        </div>

        <div className="options__tabs d-flex d-md-none align-items-center justify-content-between">
          <button
            type="button"
            className={
              activeOption === "standard"
                ? "tab tab--active color--blue button-small font-weight--600"
                : "tab color--blue button-small"
            }
            onClick={() => setActiveOption("standard")}
          >
            Standard
          </button>
          <button
            type="button"
            className={
              activeOption === "advanced"
                ? "tab tab--active color--blue button-small font-weight--600"
                : "tab color--blue button-small"
            }
            onClick={() => setActiveOption("advanced")}
          >
            Advanced
          </button>
          <button
            type="button"
            className={
              activeOption === "genome"
                ? "tab tab--active color--blue button-small font-weight--600"
                : "tab color--blue button-small"
            }
            onClick={() => setActiveOption("genome")}
          >
            Genome
          </button>
        </div>

        <div className="d-none d-md-flex row justify-content-end mb-4">
          <div className="col-6" />
          <div className="col-2">
            <div className="plan text-center">
              <p className="font-weight--600">Standard</p>

              <div
                className="separator mt-3 mb-4 bg--azul"
                style={{ height: "14px" }}
              />

              <p className="font-weight--500">Desde la semana 10</p>
            </div>
          </div>
          <div className="col-2">
            <div className="plan text-center">
              <p className="font-weight--600">Advanced</p>

              <div
                className="separator mt-3 mb-4 bg--rosa"
                style={{ height: "14px" }}
              />

              <p className="font-weight--500">Desde la semana 9</p>
            </div>
          </div>
          <div className="col-2">
            <div className="plan text-center">
              <p className="font-weight--600">Genome</p>

              <div
                className="separator mt-3 mb-4 bg--magenta"
                style={{ height: "14px" }}
              />

              <p className="font-weight--500">Desde la semana 9</p>
            </div>
          </div>
        </div>

        <div className="options">
          <div className="row">
            {options.map((option, index) => (
              <motion.div
                className="col-12"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                variants={{
                  visible: {
                    opacity: 1,
                  },
                  hidden: {
                    opacity: 0,
                  },
                }}
              >
                <div className="option">
                  <div className="row w-100 justify-content-between">
                    <div className="col-10 col-md-6">
                      <p
                        className="name"
                        dangerouslySetInnerHTML={{ __html: option.name }}
                      />
                    </div>

                    <div
                      className={
                        activeOption === "standard"
                          ? "column column--active col-2"
                          : "column col-2"
                      }
                    >
                      {option.standard ? <IconCheck /> : <IconClose />}
                    </div>

                    <div
                      className={
                        activeOption === "advanced"
                          ? "column column--active col-2"
                          : "column col-2"
                      }
                    >
                      {option.advanced ? <IconCheck /> : <IconClose />}
                    </div>

                    <div
                      className={
                        activeOption === "genome"
                          ? "column column--active col-2"
                          : "column col-2"
                      }
                    >
                      {option.genome ? <IconCheck /> : <IconClose />}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="options__controls d-md-none">
          <button
            type="button"
            className="control control--prev bg--azul bg-hover--magenta"
            onClick={() => handlePrev()}
          >
            <IconCaret />
          </button>

          <button
            type="button"
            className="control control--right bg--azul bg-hover--magenta"
            onClick={() => handleNext()}
          >
            <IconCaret />
          </button>
        </div>

        <div className="options__button d-flex justify-content-center">
          <SecondaryButtonLinkExternal
            href="/cuadro.pdf"
            className="border--azul border-hover--rosa"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconDownload className="me-2" />
            Descargar cuadro completo
          </SecondaryButtonLinkExternal>
        </div>
      </Container>
    </StyledOptions>
  )
}

export default Options
