import React from "react";

// Libraries
import styled from "styled-components";
import PropTypes from "prop-types";

// Icons
import Quotation from "assets/icons/testimonials/quote.inline.svg";
import User from "assets/icons/user.png";

// Utils
import breakpoints from "utils/breakpoints";

const StyledCarrousel = styled.div`
  position: relative;
  padding: 57px 33px 64px 26px;
  background: rgba(255, 255, 255, 0.1);
  border: 3px solid rgba(255, 255, 255, 0.272);
  box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  z-index: 900;
  max-height: 631px;
  height: 100vh;

  ${breakpoints.medium`
    padding: 110px 220px 104px 171px;
    max-height: 570px;
  `}

  .testimonio {
    margin-top: 14px;
    margin-bottom: 46px;
    font-size: 20px;
    line-height: 36px;
    max-height: 288px;
    height: 100%;

    ${breakpoints.medium`
      font-size: 24px;
      line-height: 44px;
      max-height: 175px;
      height: 100%;
      max-width: 692px !important;
      width: 100% !important;
    `}
  }

  .institucion__container {
    .logo {
      display: flex;
    }

    .institucion {
      margin-top: 11px;

      font-size: 16px;
      line-height: 28px;

      ${breakpoints.medium`
        font-size: 20px;
        line-height: 36px;
      `}
    }
  }
`;

const CarouselCardUser = ({ quote, name }) => (
  <StyledCarrousel>
    <Quotation />
    <p className="testimonio">{quote}</p>
    <div className="institucion__container">
      <img src={User} alt={name} style={{ width: "50px", height: "50px" }} />
      <p className="institucion color--rosa font-weight--600">{name}</p>
    </div>
  </StyledCarrousel>
);

CarouselCardUser.propTypes = {
  name: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
};
export default CarouselCardUser;
