import React from "react";

// Libraries
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { motion } from "framer-motion";

// Icons
import Arrows from "assets/icons/hero/coolicon.inline.svg";

// Variables
import breakpoint from "utils/breakpoints";
import { colors } from "utils/variables";

// Components
import ButtonLink from "components/button";
import Separator from "components/separator";
import { Link } from "gatsby";

const StyledHero = styled(motion.section)`
  width: 100%;
  position: relative;

  ${breakpoint.medium`
    max-height: 910px;
    height: 100vh;
    display: flex;
  `}

  .hero__image {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;

    ${breakpoint.small`
      height: 80vh;
    `}

    ${breakpoint.medium`
      width: 50%;
      height: 100%;
      order: 1;
    `}

    .image {
      > div {
        height: 100%;
      }
    }
  }

  .hero__content {
    padding: 25px 24px 85px 24px;
    text-align: center;

    ${breakpoint.medium`
      width: 50%;
      height: 100%;
      order: 0;
      padding-left: calc(((100vw - 1024px) / 2) + 60px);
      text-align: left;
    `}

    ${breakpoint.large`
      width: 50%;
      height: 100%;
      padding-left: calc(((100vw - 1312px) / 2) + 112px);
      
    `}

    .content {
      ${breakpoint.medium`
        max-width: 446px;
      `}

      ${breakpoint.large`
        max-width: 480px;
        width: 100%;
      `}

      .content__title {
        margin-bottom: 10px;

        ${breakpoint.medium`
          max-width: 446px;
        `}
      }

      .hero__buttons {
        margin-top: 36px;

        ${breakpoint.small`
          display: inline-flex;
        
        `}

        ${breakpoint.medium`
          margin-top: 48px;
          white-space: nowrap;
        `}

        .contact {
          width: 100%;
          font-size: 16px;
          font-weight: bold;
          line-height: 14px;
          border: 2px solid;
          transition: all 0.3s ease;
          padding: 20px 123px;

          ${breakpoint.small`
            width: auto;
            padding: 20px 55px;
          `}

          ${breakpoint.medium`
            align-items: center;
            align-items: center;
            justify-content: center;
          `}
        }
        svg {
          margin-left: 13px;
        }

        .button {
          margin-top: 30px;
          transition: none;
          &:hover {
            svg {
              * {
                fill: ${colors.azul};
              }
            }
          }

          ${breakpoint.small`
            margin-top: 0;
          `}
        }
      }
    }
  }

  .hero__separator {
    position: absolute;
    z-index: 50;

    &--left {
      top: 15vh;
      left: 3vw;

      ${breakpoint.extraLarge`
        left: 8vw;
      `}
    }

    &--center {
      width: 40px;
      top: calc(50vh - 5px);
      right: 0;
      left: 0;
      margin: auto;
    }

    &--right {
      top: 10vh;
      right: 0;
    }
  }
`;

const Hero = () => (
  <StyledHero>
    <div className="hero__image d-flex">
      <motion.div
        animate={{
          scale: [1.1, 1],
        }}
        transition={{
          duration: 0.5,
        }}
        className="image"
      >
        <StaticImage
          src="../../assets/images/hero/hero-image.png"
          alt="Genesia"
        />
      </motion.div>
    </div>

    <motion.div
      className="hero__content d-md-flex align-items-center"
      animate={{
        opacity: [0, 1],
        y: [50, 0],
      }}
      transition={{
        duration: 0.5,
      }}
    >
      <div className="content" data-parallax="vertical">
        <h1 className="content__title font-weight--400">
          Test prenatal no invasivo
        </h1>
        <p className="paragraph--medium">
          Analiza la salud genética de tu bebé y disfruta de tu embarazo con
          mayor tranquilidad.
        </p>
        <div className="hero__buttons">
          <Link
            to="/#contact"
            className="contact bg--magenta bg-hover--rosa mb-2 mb-md-0 color--blanco"
          >
            Contactar
          </Link>

          <ButtonLink
            to="/#que-es"
            className="button bg--blanco color--azul color-hover--magenta"
          >
            Quiero saber más <Arrows />
          </ButtonLink>
        </div>
      </div>
    </motion.div>

    <div
      className="hero__separator hero__separator--left d-none d-md-flex"
      data-parallax="vertical"
    >
      <Separator colors={[colors.magenta, colors.azul]} />
    </div>

    <div
      className="hero__separator hero__separator--center"
      data-parallax="vertical"
    >
      <Separator colors={[colors.magenta, colors.azul]} />
    </div>

    <div
      className="hero__separator hero__separator--right d-none d-md-flex"
      data-parallax="vertical"
    >
      <Separator colors={[colors.magenta, colors.azul]} />
    </div>
  </StyledHero>
);

export default Hero;
