import React from "react";

// Libraries
import styled from "styled-components";
import { motion } from "framer-motion";

// Utils
import breakpoints from "utils/breakpoints";

// Container
import Container from "components/container";

// Components
import FeaturesCard from "components/features-card";

// Icons
import IconAsesoramiento from "assets/icons/testimonials/asesoramiento.inline.svg";
import IconEstudio from "assets/icons/testimonials/costo.inline.svg";
import IconTecnologia from "assets/icons/testimonials/tecnologia-icon.inline.svg";
import IconResultados from "assets/icons/testimonials/resultados.inline.svg";

const StyledFeatures = styled.section`
  position: relative;
  top: -20px;

  ${breakpoints.medium`
    top: -45px;
    padding-bottom: 0 !important;
  `}

  .card-wrapper {
    margin-bottom: 32px;

    ${breakpoints.large`
      margin: 0;
    `}
  }
`;

const Features = () => {
  const featuresData = [
    {
      icon: <IconAsesoramiento />,
      title: "Asesoramiento personalizado",
      description:
        "A cargo de un equipo médico experto en genética reproductiva.",
    },
    {
      icon: <IconResultados />,
      title: "Resultados rápidos",
      description: "Proceso diseñado para optimizar tiempos de respuesta. ",
    },
    {
      icon: <IconTecnologia />,
      title: "Tecnología de mayor precisión",
      description:
        "Utilizamos secuenciación masiva en paralelo para el mejor desempeño.",
    },
    {
      icon: <IconEstudio />,
      title: "Estudio confirmatorio sin costo adicional",
      description:
        "Ante un resultado positivo, cubrimos el estudio confirmatorio sin costo adicional.",
      footer: "*Consulta restricciones",
    },
  ];

  return (
    <StyledFeatures>
      <Container>
        <div className="row" data-parallax="vertical">
          {featuresData.map((data, index) => (
            <motion.div
              key={data.title}
              className="card-wrapper col-12 col-sm-6 col-lg-3"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{
                delay: index * 0.1,
              }}
              variants={{
                visible: {
                  y: 0,
                  opacity: 1,
                  scale: 1,
                },
                hidden: {
                  y: 64,
                  opacity: 0,
                  scale: 0.9,
                },
              }}
            >
              <FeaturesCard
                icon={data.icon}
                title={data.title}
                description={data.description}
                footer={data.footer}
              />
            </motion.div>
          ))}
        </div>
      </Container>
    </StyledFeatures>
  );
};

export default Features;
