import React from "react";

// Libraries
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import breakpoints from "utils/breakpoints";

// Variables
import { colors } from "utils/variables";

const CardStyle = styled.div`
  background-color: ${colors.blanco};
  box-shadow: 0px 0.793533px 7.93533px rgba(109, 141, 173, 0.25);
  padding: 60px 49px 69px 51px;
  text-align: center;
  color: ${colors.azul};
  margin-bottom: 10px;
  height: 100%;

  ${breakpoints.medium`
    padding: 60px 38px 66px 36px;
  `}

  .number {
    margin: 0 auto 21px auto;
    color: ${colors.magenta};
    border: 2px solid #d53968;
    border-radius: 100px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .descrition {
    line-height: 29px;
  }
`;

const Card = ({ number, title, description, footer }) => (
  <CardStyle>
    <p className="number">{number}</p>
    <h4 className="title">{title}</h4>
    <p className="description paragraph--small">{description}</p>
    <p className="description paragraph--small">{footer}</p>
  </CardStyle>
);

Card.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Card;
