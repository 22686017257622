import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Icon
import Arrow from "assets/icons/faq/arrow.inline.svg"

// Styles
import styled from "styled-components"

const StyledFAQ = styled.section`
  .title {
    color: ${colors.blue};
    font-weight: 600;
    text-align: left;
    line-height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
    transition: none !important;

    ${breakpoints.small`
      justify-content: space-between;
      width: 100%;
    `}

    svg {
      margin-left: 21px;
      right: 1 !important;
    }
  }

  .rosa {
    color: ${colors.magenta};
  }

  .rotate {
    svg {
      transform: rotate(90deg);
      * {
        fill: ${colors.rosa};
        stroke: ${colors.rosa};
      }
    }
  }

  .content {
    margin-top: 18px;
    font-weight: 500;
    text-align: left;
  }
`

const FaqCard = ({ title, content }) => {
  const [active, setActive] = useState(false)

  const handleClick = () => {
    setActive(!active)
  }

  return (
    <StyledFAQ>
      <div>
        <button
          onClick={handleClick}
          type="button"
          className={active ? "rosa title rotate" : "title"}
        >
          {title}
          <span>
            <Arrow />
          </span>
        </button>
        {active && <p className="content paragraph--small">{content}</p>}
      </div>
    </StyledFAQ>
  )
}

FaqCard.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default FaqCard
