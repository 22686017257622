import React from "react";

// Libraries
import styled from "styled-components";
import { motion } from "framer-motion";

// Variables
import { colors, gradients, phoneNumberForCalls, urls } from "utils/variables";
import breakpoints from "utils/breakpoints";

// // Icons
import Llamar from "assets/icons/call-to-action/coolicon.inline.svg";
import Whatsapp from "assets/icons/call-to-action/whatsapp.inline.svg";
import Background from "assets/icons/call-to-action/call-to-action-background.svg";

// Container
import Container from "components/container";
import { PrimaryButtonExternalLink } from "components/button";

const StyledCTA = styled.section`
  text-align: center;
  color: ${colors.blanco};
  padding: 230px 0 105px 0;
  background: ${gradients.magenta};

  ${breakpoints.medium`
    padding: 198px 0 187px 0;
    background: url('${Background}'), ${gradients.magenta};
    // background-size: 100% 440px;
    background-position: center;
    background-repeat: no-repeat;
    // background-attachment: fixed;
  `}

  .cta__title {
    margin: 0px 20px 16px 20px;
  }

  .cta__text {
    ${breakpoints.medium`
      margin-right: 80px;
      margin-left: 80px;
    `}
  }

  .cta__butons {
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    justify-content: center;

    ${breakpoints.medium`
      flex-direction: row;
      justify-content: center;

    `}

    a {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      white-space: nowrap;

      ${breakpoints.medium`
        margin-right: 27px;

        &:last-of-type {
          margin-right: 0;
        }
      `}
    }

    .cta__button {
      svg {
        margin-right: 11px;
      }
    }
  }
`;

const Cta = () => (
  <StyledCTA>
    <Container small>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={{
          visible: {
            opacity: 1,
          },
          hidden: {
            opacity: 0,
          },
        }}
        transition={{
          duration: 0.6,
        }}
      >
        <h2 className="cta__title">Evita la ansiedad en tu embarazo</h2>
        <p className="cta__text paragraph--medium">
          Un resultado al principio del embarazo que te permite evitar
          ansiedades y miedos, relacionadas con la salud de tu bebé.
        </p>
        <div className="cta__butons">
          <PrimaryButtonExternalLink
            href={`tel:${phoneNumberForCalls}`}
            className="bg--azul cta__button bg-hover--rosa color-hover--blanco"
          >
            <Llamar /> Llamar
          </PrimaryButtonExternalLink>

          {urls.whatsapp && (
            <PrimaryButtonExternalLink
              href={urls.whatsapp}
              target="_blank"
              rel="noopener noreferrer"
              className="bg--azul cta__button bg-hover--rosa color-hover--blanco"
            >
              <Whatsapp /> Enviar un Whatsapp
            </PrimaryButtonExternalLink>
          )}
        </div>
      </motion.div>
    </Container>
  </StyledCTA>
);

export default Cta;
