import React from "react";

// Styled
import styled from "styled-components";

// Components
import FaqCard from "components/faq-card";

// Container
import Container from "components/container";

// Utils
import { colors } from "utils/variables";
import breakpoints from "utils/breakpoints";
import { motion } from "framer-motion";

const faqs = [
  {
    title: "¿Qué beneficios trae realizar este test?",
    content:
      "El principal beneficio de este test es que brinda una gran tranquilidad a la futura madre, ya que posee una alta tasa de detección, lo que permite descartar la presencia de anomalías genéticas en el bebé con una elevada certeza, sin la necesidad de un procedimiento invasivo.",
  },
  {
    title: "¿Está recomendado para cualquier futura mamá?",
    content:
      "Si, este test puede ser realizado por cualquier futura mamá para brindar tranquilidad durante el embarazo. Sin embargo, está especialmente indicado cuando el embarazo es considerado de riesgo. Esto puede deberse a una edad materna mayor a 35 años, a casos de anomalías genéticas en la familia, o a un hallazgo ecográfico que sugiera la presencia de un desorden genético en el bebé.",
  },
  {
    title: "¿La realización de este estudio tiene algún riesgo para mi bebé?",
    content:
      "Este estudio no presenta ningún riesgo para el bebé ni para la mamá, ya que el análisis se lleva a cabo sobre una simple muestra de sangre obtenida por venopunción del brazo de la madre, tal como en cualquier análisis de rutina.",
  },
  {
    title: "¿Y cuán confiables son estos resultados?",
    content:
      "El gran valor de estos estudios radica en su alta sensibilidad y especificidad. La sensibilidad de este estudio es mayor al 99,99% para la mayoría de las anomalías estudiadas. Esto significa que si existe una anomalía en el feto en gestación, este test la va a detectar en más del 99,99% de los casos. Esto también significa que si el test no detectó una anomalía, podemos asumir con una elevadísima certeza que dicha anomalía no está presente, brindando tranquilidad a la madre. La alta especificidad significa que cuando el test indique la presencia de una anomalía, podemos asumir con una muy elevada certeza que esa anomalía está presente. En estos casos se pone en marcha un protocolo en el cual ofreceremos un asesoramiento personalizado sin cargo con nuestro Director Médico y Genetista, el Dr. Gabriel Ercoli, quien determinará la necesidad o no de llevar a cabo un procedimiento invasivo de confirmación diagnóstica. Dicho procedimiento es ofrecido sin cargo alguno.",
  },
  {
    title:
      "¿Tengo que hacer alguna preparación especial al momento de ir a tomarme la muestra?",
    content:
      "No, en lo absoluto, no hace falta estar en ayunas ni suspender ninguna medicación. Sí sugerimos una buena hidratación dos días antes para una mejor extracción.",
  },
  {
    title:
      "Si me hago el estudio genético no invasivo, ¿debo realizarme el estudio de la traslucencia nucal también (TN)?",
    content:
      "Desde luego que si. El ultrasonido estructural de primer trimestre es el complemento ideal para un estudio genético prenatal no invasivo, ya que puede estimar problemas de índole embriológica por observación directa. Sin embargo, en cuanto a identificación de anomalías genéticas, el ultrasonido estructural tiene una tasa de detección de alrededor del 85%, dejando un 15% aproximado de falsos negativos ya que no analiza el ADN fetal.",
  },
  {
    title: "¿Cuánto demoran los resultados?",
    content:
      "Los resultados se obtienen rápidamente de 7 a 10 días hábiles a partir de la fecha de extracción.",
  },
];

const StyledFAQ = styled.section`
  text-align: center;
  background-color: ${colors.gris};
  padding: 83px 0px 77px 0px;

  ${breakpoints.medium`
    padding: 147px 0px 97px 0px;
  `}

  .faq__title {
    margin-bottom: 38px;
  }

  .preguntas {
    box-shadow: 0px 1px 0px #c4cdd3;
    padding: 32px 24px 18px 24px;
  }
`;

const Faq = () => (
  <StyledFAQ>
    <Container small data-parallax="vertical">
      <h4 className="faq__title">Preguntas Frecuentes</h4>
      <div>
        {faqs.map((faq, index) => (
          <motion.div
            key={faq.title}
            className="preguntas"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{
              delay: index * 0.1,
            }}
            viewport={{ once: true }}
          >
            <FaqCard title={faq.title} content={faq.content} />
          </motion.div>
        ))}
      </div>
    </Container>
  </StyledFAQ>
);

export default Faq;
