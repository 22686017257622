import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { motion } from "framer-motion"

const StyledSeparator = styled(motion.div)`
  width: ${(props) => props.width};
  height: 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  .block {
    height: 100%;
    flex-shrink: 0;
  }
`

const Separator = (props) => {
  const { width, colors, className } = props

  return (
    <StyledSeparator
      width={width}
      initial={{ scaleX: "0.5" }}
      whileInView={{ scaleX: "1" }}
      viewport={{ once: true }}
      transition={{ delay: 0.25 }}
      className={className}
    >
      {colors.map((color) => (
        <div
          className="block"
          style={{ width: `${100 / colors.length}%`, backgroundColor: color }}
        />
      ))}
    </StyledSeparator>
  )
}

Separator.propTypes = {
  width: PropTypes.string,
  colors: PropTypes.instanceOf(PropTypes.array).isRequired,
  className: PropTypes.string,
}

Separator.defaultProps = {
  width: "40px",
  className: "",
}

export default Separator
