import React, { useState } from "react";
import { navigate } from "gatsby";

// Libraries
import styled from "styled-components";
import { transparentize } from "polished";

// Utils
import { colors, gradients } from "utils/variables/";
import breakpoint from "utils/breakpoints/";

// Components
import Container from "components/container/";
import Form from "components/form/";
import { PrimaryButton, SecondaryButton } from "components/button";

// Icons
import IconCheck from "assets/icons/icon-check.inline.svg";
import IconClose from "assets/icons/icon-close.inline.svg";
import IconTitle from "assets/icons/contacto/icon-title.inline.svg";
import Background from "assets/icons/contacto/contact-background.svg";

import ReCAPTCHA from "react-google-recaptcha";

const data = require("../../assets/data/data.json");

const StyledContact = styled.section`
  padding: 80px 0;
  background: url("${Background}"), ${gradients.blue};
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;

  ${breakpoint.medium`
    padding: 170px 0 150px 0;
  `}

  .contact__content {
    margin-bottom: 30px !important;
    ${breakpoint.medium`
      text-align: left !important;
    `}

    .title {
      margin-top: 15px;
      margin-bottom: 16px;

      ${breakpoint.medium`
        margin-top: 23px;
      `}
    }

    .paragraph--small {
      max-width: 357px;
    }
  }

  .contact__form {
    position: relative;

    ${breakpoint.medium`
      margin-top: 46px;
    
    `}

    .form {
      opacity: ${(props) => (props.showSuccess || props.showError ? "0" : "1")};
      transition: all 0.3s ease;

      input::placeholder {
        color: ${colors.gris__2};
      }
    }

    .button__send-contulta {
      width: 100%;
      text-align: center;

      ${breakpoint.medium`
        width: auto;
      
      `}
    }
  }

  .contact__form-message {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .content {
      width: 100%;
      aspect-ratio: 1;
      padding: 48px 24px;
      border: 3px solid ${transparentize(0.7, colors.blanco)};
      background-color: ${transparentize(0.9, colors.blanco)};
      backdrop-filter: blur(20px);
      text-align: center;

      ${breakpoint.medium`
        padding-top: 110px 58px;
      `}

      svg {
        width: 48px;
        height: 48px;
      }
    }
  }

  /* make all select and options fot sizes larger */
  select {
    font-size: 1.2rem;
    padding: 0.5rem;
    margin: 0.5rem;
    border: 1px solid ${colors.gris__2};
    border-radius: 0.5rem;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: ${colors.blanco};
  }
`;

const Contact = () => {
  const [formType, setFormType] = useState("");
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [formPatientFields, setFormPatientFields] = useState({
    name: "",
    email: "",
    phone: "",
    channel: "Indistinto",
    weeks: "",
  });
  const [formPatientValidation, setFormPatientValidation] = useState({
    name: true,
    email: true,
    phone: true,
    channel: true,
    weeks: true,
  });
  const [formDoctorFields, setFormDoctorFields] = useState({
    name: "",
    email: "",
    phone: "",
    speciality: "",
    channel: "Indistinto",
    institution: "",
    message: "",
  });
  const [formDoctorValidation, setFormDoctorValidation] = useState({
    name: true,
    email: true,
    phone: true,
    speciality: true,
    channel: true,
    institution: true,
    message: true,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    if (formType === "patient") {
      setFormPatientFields({
        ...formPatientFields,
        [name]: value,
      });
      if (
        formPatientValidation[name] !== "undefined" &&
        !formPatientValidation[name]
      ) {
        setFormPatientValidation({
          ...formPatientValidation,
          [name]: true,
        });
      }
    }

    if (formType === "doctor") {
      setFormDoctorFields({
        ...formDoctorFields,
        [name]: value,
      });
      if (
        formDoctorValidation[name] !== "undefined" &&
        !formDoctorValidation[name]
      ) {
        setFormDoctorValidation({
          ...formDoctorValidation,
          [name]: true,
        });
      }
    }
  };

  const handleFormPatientValidation = () => {
    const _formPatientValidation = formPatientValidation;

    Object.keys(_formPatientValidation).forEach((key) => {
      _formPatientValidation[key] = formPatientFields[key] !== "";
    });

    setFormPatientValidation({
      ...formPatientValidation,
      ..._formPatientValidation,
    });
  };

  const handleFormDoctorValidation = () => {
    const _formDoctorValidation = formDoctorValidation;

    Object.keys(_formDoctorValidation).forEach((key) => {
      _formDoctorValidation[key] =
        key === "speciality" ||
        key === "institution" ||
        key === "message" ||
        formDoctorFields[key] !== "";
    });

    setFormDoctorValidation({
      ...formDoctorValidation,
      ..._formDoctorValidation,
    });
  };

  const formPatientIsValid = () =>
    !Object.values(formPatientValidation).includes(false);
  const formDoctorIsValid = () =>
    !Object.values(formDoctorValidation).includes(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validCaptcha) return;

    if (formType === "patient") {
      handleFormPatientValidation();
      if (formPatientIsValid()) {
        await fetch("https://api.genesia.la/", {
          method: "post",
          body: JSON.stringify({
            action: "form_submit",
            formType,
            amoCRMCountry: data.amoCRMCountry,
            amoCRMUser: data.amoCRMUser,
            adCampaignType: "TBD",
            to: data.to,
            ...formPatientFields,
          }),
        }).then((response) => {
          if (response.status === 200) {
            setShowSuccess(true);
            setTimeout(() => {
              navigate("/gracias");
            }, 1000);
          } else {
            setShowError(true);
          }
        });
      }
    }
    if (formType === "doctor") {
      handleFormDoctorValidation();
      if (formDoctorIsValid()) {
        await fetch("https://api.genesia.la/", {
          method: "post",
          body: JSON.stringify({
            action: "form_submit",
            formType,
            amoCRMCountry: data.amoCRMCountry,
            amoCRMUser: data.amoCRMUser,
            adCampaignType: "TBD",
            to: data.to,
            ...formDoctorFields,
          }),
        }).then((response) => {
          if (response.status === 200) {
            setShowSuccess(true);
            setTimeout(() => {
              navigate("/gracias");
            }, 1000);
          } else {
            setShowError(true);
          }
        });
      }
    }
  };

  return (
    <StyledContact
      showSuccess={showSuccess}
      showError={showError}
      className="color--blanco"
      id="contact"
    >
      <Container small data-parallax="vertical">
        <div className="row justify-content-between">
          <div className="contact__content col-12 col-md-5 mb-4 text-center">
            <IconTitle />
            <h3 className="title">Contactanos</h3>

            <p className="paragraph--medium mb-5 font-weight--500">
              Despeja todas tus dudas con expertos en genética, sin ningún
              compromiso.
            </p>

            {data?.addresses?.length > 1 && (
              <p className="paragraph--medium mb-4 font-weight--600">
                Oficinas
              </p>
            )}

            {data?.addresses?.map((address, index) => (
              <p
                className="paragraph--small mb-4 font-weight--500"
                key={address.title}
              >
                <b className="color--rosa font-weight--600">{address.title}</b>
                {address.phoneNumber && (
                  <>
                    <br />
                    {address.phoneNumber}
                  </>
                )}
                {address.street && (
                  <>
                    <br />
                    {address.street}
                  </>
                )}
                {address.city && (
                  <>
                    <br />
                    {address.city}
                  </>
                )}
                {address.zipcode && (
                  <>
                    <br />
                    {address.zipcode}
                  </>
                )}
                {address.description && (
                  <>
                    <br />
                    {address.description}
                  </>
                )}
              </p>
            ))}

            {/* <p className="paragraph--small font-weight--500">
              <b className="color--rosa font-weight--600">
                Centros de recolección
              </b>
              <br />
              Contamos con más de 40 centros de recolección distribuidos en el
              país.
            </p> */}
          </div>

          <div className="contact__form col-12 col-md-6">
            {!formType && (
              <div style={{ textAlign: "center" }}>
                <PrimaryButton
                  type="button"
                  className="button__send-contulta bg--magenta bg-hover--rosa mx-md-4 mb-3"
                  onClick={() => setFormType("doctor")}
                >
                  Soy médico
                </PrimaryButton>
                <PrimaryButton
                  type="button"
                  className="button__send-contulta bg--magenta bg-hover--rosa mx-md-4 mb-3"
                  onClick={() => setFormType("patient")}
                >
                  Soy paciente
                </PrimaryButton>
              </div>
            )}

            {formType === "patient" && (
              <Form className="form">
                <div className="form__group">
                  <label htmlFor="name">
                    Nombre y apellido
                    <input
                      type="text"
                      className="mt-3 color--gris__2"
                      id="name"
                      name="name"
                      placeholder="Tu nombre y apellido"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formPatientValidation.name && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu nombre y apellido
                    </small>
                  )}
                </div>
                <div className="form__group">
                  <label htmlFor="email">
                    Email
                    <input
                      type="email"
                      className="mt-3"
                      id="email"
                      name="email"
                      placeholder="Tu email"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formPatientValidation.email && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu email
                    </small>
                  )}
                </div>
                <div className="form__group">
                  <label htmlFor="phone">
                    WhatsApp / Teléfono
                    <input
                      type="text"
                      className="mt-3"
                      id="phone"
                      name="phone"
                      placeholder="Ej. 11 4567763"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formPatientValidation.phone && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu WhatsApp / teléfono
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="channel">
                    ¿Por cuál medio te gustaría ser contactado/a?
                    <br></br>
                    <select
                      className="mt-3"
                      id="channel"
                      name="channel"
                      onChange={handleInputChange}
                    >
                      <option value="Indistinto">Indistinto</option>
                      <option value="Correo electrónico">
                        Correo electrónico
                      </option>
                      <option value="Llamada telefónica">
                        Llamada telefónica
                      </option>
                      <option value="Whatsapp">Whatsapp</option>
                    </select>
                  </label>

                  {!formPatientValidation.channel && (
                    <small className="form__message color--magenta">
                      Por favor selecciona una opción
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="weeks">
                    ¿Cuántas semanas de gestación tenés?
                    <br></br>
                    <select
                      className="mt-3"
                      id="weeks"
                      name="weeks"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccioná una opción</option>
                      <option value="Menos de 4">Menos de 4</option>
                      <option value="Semana 5">Semana 5</option>
                      <option value="Semana 6">Semana 6</option>
                      <option value="Semana 7">Semana 7</option>
                      <option value="Semana 8">Semana 8</option>
                      <option value="Semana 9">Semana 9</option>
                      <option value="Semana 10">Semana 10</option>
                      <option value="Semana 11">Semana 11</option>
                      <option value="Semana 12">Semana 12</option>
                      <option value="Más de 12">Más de 12</option>
                      <option value="No aplica">No aplica</option>
                    </select>
                  </label>

                  {!formPatientValidation.weeks && (
                    <small className="form__message color--magenta">
                      Por favor selecciona una opción
                    </small>
                  )}
                </div>

                <ReCAPTCHA
                  className="mb-5"
                  sitekey="6LfimVMnAAAAADcPc7DacJwSZfJpMjjpVy-Ay5Ay"
                  onChange={() => setValidCaptcha(true)}
                  onExpired={() => setValidCaptcha(false)}
                  onErrored={() => setValidCaptcha(false)}
                />

                <div className="button d-flex justify-content-end">
                  <PrimaryButton
                    disabled={!validCaptcha}
                    type="button"
                    className="button__send-contulta bg--magenta bg-hover--rosa"
                    onClick={handleSubmit}
                  >
                    Enviar consulta
                  </PrimaryButton>
                </div>
              </Form>
            )}

            {formType === "doctor" && (
              <Form className="form">
                <div className="form__group">
                  <label htmlFor="name">
                    Nombre y apellido
                    <input
                      type="text"
                      className="mt-3 color--gris__2"
                      id="name"
                      name="name"
                      placeholder="Tu nombre y apellido"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formDoctorValidation.name && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu nombre y apellido
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="email">
                    Email
                    <input
                      type="email"
                      className="mt-3"
                      id="email"
                      name="email"
                      placeholder="Tu email"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formDoctorValidation.email && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu email
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="phone">
                    WhatsApp / Teléfono
                    <input
                      type="text"
                      className="mt-3"
                      id="phone"
                      name="phone"
                      placeholder="Ej. 11 4567763"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formDoctorValidation.phone && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu WhatsApp / teléfono
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="speciality">
                    Especialidad
                    <br></br>
                    <select
                      className="mt-3"
                      id="speciality"
                      name="speciality"
                      onChange={handleInputChange}
                    >
                      <option value="">Seleccioná una opción</option>
                      <option value="Tocoginecología">Tocoginecología</option>
                      <option value="Obstetricia">Obstetricia</option>
                      <option value="Ginecología">Ginecología</option>
                      <option value="Fertilidad">Fertilidad</option>
                      <option value="Otra">Otra</option>
                    </select>
                  </label>

                  {!formDoctorValidation.speciality && (
                    <small className="form__message color--magenta">
                      Por favor seleccioná una opción
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="channel">
                    ¿Por cuál medio te gustaría ser contactado/a?
                    <br></br>
                    <select
                      className="mt-3"
                      id="channel"
                      name="channel"
                      onChange={handleInputChange}
                    >
                      <option value="Indistinto">Indistinto</option>
                      <option value="Correo electrónico">
                        Correo electrónico
                      </option>
                      <option value="Llamada telefónica">
                        Llamada telefónica
                      </option>
                      <option value="Whatsapp">Whatsapp</option>
                    </select>
                  </label>

                  {!formDoctorValidation.channel && (
                    <small className="form__message color--magenta">
                      Por favor seleccioná una opción
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="institution">
                    Institución
                    <input
                      type="text"
                      className="mt-3 color--gris__2"
                      id="institution"
                      name="institution"
                      placeholder="Tu institución"
                      onChange={handleInputChange}
                    />
                  </label>

                  {!formDoctorValidation.institution && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu institución
                    </small>
                  )}
                </div>

                <div className="form__group">
                  <label htmlFor="message">
                    Mensaje
                    <input
                      type="text"
                      className="mt-3"
                      id="message"
                      name="message"
                      placeholder="Escribe tu consulta aquí"
                      onChange={handleInputChange}
                    />
                  </label>
                  {!formDoctorValidation.message && (
                    <small className="form__message color--magenta">
                      Por favor ingresá tu mensaje
                    </small>
                  )}
                </div>

                <ReCAPTCHA
                  className="mb-5"
                  sitekey="6LfimVMnAAAAADcPc7DacJwSZfJpMjjpVy-Ay5Ay"
                  onChange={() => setValidCaptcha(true)}
                  onExpired={() => setValidCaptcha(false)}
                  onErrored={() => setValidCaptcha(false)}
                />

                <div className="button d-flex justify-content-end">
                  <PrimaryButton
                    disabled={!validCaptcha}
                    type="button"
                    className="button__send-contulta bg--magenta bg-hover--rosa"
                    onClick={handleSubmit}
                  >
                    Enviar consulta
                  </PrimaryButton>
                </div>
              </Form>
            )}

            {showSuccess && (
              <div className="contact__form-message">
                <div className="content d-flex align-items-center justify-content-center">
                  <div>
                    <IconCheck className="mb-4" />

                    <h4 className="mb-2 font-weight--700">
                      Tu mensaje fue enviado con éxito.
                    </h4>

                    <p className="paragraph--small">
                      Nuestro equipo se comunicará contigo lo antes posible.
                      <br />
                      ¡Gracias por comunicarte!
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3 mt-md-4">
                  <SecondaryButton
                    type="button"
                    className="border--blanco border-hover--rosa color--blanco"
                    onClick={() => setShowSuccess(false)}
                  >
                    Enviar otra consulta
                  </SecondaryButton>
                </div>
              </div>
            )}

            {showError && (
              <div className="contact__form-message">
                <div className="content d-flex align-items-center justify-content-center">
                  <div>
                    <IconClose className="mb-4" />

                    <h4 className="mb-2 font-weight--700">Algo salió mal.</h4>

                    <p className="paragraph--small">
                      Por favor, intenta nuevamente.
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3 mt-md-4">
                  <SecondaryButton
                    type="button"
                    className="border--blanco border-hover--rosa color--blanco"
                    onClick={() => setShowError(false)}
                  >
                    Intentar de nuevo
                  </SecondaryButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </StyledContact>
  );
};

export default Contact;
