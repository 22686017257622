// Libraries
import styled from "styled-components"
import { transparentize } from "polished"

// Utils
import { colors } from "utils/variables"
// import breakpoint from "utils/breakpoints/"

const Form = styled.form`
  .form__group {
    position: relative;
    margin-bottom: 48px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-transform: uppercase;
  }

  input,
  textarea {
    all: unset;
    width: 100%;
    display: block;
    padding: 14px 20px;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    border-bottom: 1px solid ${transparentize(0.6, colors.blanco)};
    box-sizing: border-box;
    transition: all 0.3s ease;

    &:focus,
    &:focus-visible {
      border-color: ${colors.rosa};
    }

    &::placeholder {
      font-weight: 500;
    }
  }

  .form__message {
    position: absolute;
    top: calc(100% + 8px);
    left: 6px;
  }
`

export default Form
