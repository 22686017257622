import React from "react";

// Libraries
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

// Icons
import Rectangulo from "assets/icons/que-es/rectangulo.inline.svg";
import Rectangulo2 from "assets/icons/que-es/rectangulo-2.inline.svg";
import Rectangulo3 from "assets/icons/que-es/rectangulo-3.inline.svg";

import RectanguloDesktop from "assets/icons/que-es/rectangulo-desktop.inline.svg";
import RectanguloDesktop2 from "assets/icons/que-es/rectangulo-desktop2.inline.svg";
import RectanguloDesktop4 from "assets/icons/que-es/rectangulo-4.inline.svg";

// Styled
import styled from "styled-components";

// Utils
import { colors } from "utils/variables";
import breakpoints from "utils/breakpoints";

const StyledQueEs = styled.section`
  background-color: ${colors.blanco};

  ${breakpoints.medium`
    display: flex;
  `}

  .title {
    margin-bottom: 16px;

    ${breakpoints.medium`
      text-align: start;
    `}
  }

  .que-es__content {
    padding: 88px 20px 0px 20px;
    text-align: center;
    background-color: ${colors.blanco};
    z-index: 800;

    ${breakpoints.medium`
      width: 50vw;
      order: 1;
      padding: 84px 135px 0px 98px;
      text-align: left;
      position: relative;
      margin-top: -60px;
    `}

    // esto es lo que muestro en desktop
    .que-es__items2 {
      display: none;

      ${breakpoints.medium`
        display: block;
        margin-top: 30px;
      `}

      .item {
        display: flex;
        margin-bottom: 24px;

        p {
          font-size: 20px;
        }

        span {
          font-weight: 600;
        }

        .desktop {
          display: none;
          ${breakpoints.medium`
            display: block;
          `}
        }

        .mobile {
          ${breakpoints.medium`
            display: none;
          `}
        }

        svg {
          position: absolute;
          margin-top: 10px;
          margin-left: -170px;
          z-index: 9998;
        }
      }

      .anomalias {
        margin-top: 78px;
        text-align: left;
      }
    }
    // end;
  }

  // esto es lo que muestro en mobile
  .que-es__items {
    margin-top: 30px;
    margin-bottom: 60px;

    ${breakpoints.medium`
      display: none
    `}

    .item {
      display: flex;
      margin-bottom: 15px;
      span {
        font-weight: 600;
      }

      svg {
        margin-top: 10px;
        margin-right: 31px;
      }
    }
    .anomalias {
      margin-top: 60px;
    }
  }

  .que-es__image {
    margin-top: 30px;

    ${breakpoints.medium`
      width: 50vw;
      max-height: 630px;
      height: 100%;
      margin: 0;
    `}
  }
`;

const QueEs = () => (
  <StyledQueEs id="que-es">
    <motion.div
      className="que-es__content"
      initial={{ y: 64, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
      data-parallax="vertical"
    >
      <h3 className="title">¿Qué es?</h3>
      <p className="paragraph--medium">
        Es un test que estudia el ADN fetal presente en la sangre materna.
        Analiza las principales condiciones genéticas e informa el sexo fetal.
      </p>
      <div className="que-es__items2 paragraph--medium">
        <div className="item">
          <div>
            <RectanguloDesktop className="desktop" />
            <Rectangulo className="mobile" />
          </div>
          <p className="paragraph--small">
            A partir de las <span>9 semanas</span> de embarazo
          </p>
        </div>
        <div className="item">
          <div>
            <RectanguloDesktop4 className="desktop" />
            <Rectangulo2 className="mobile" />
          </div>
          <p className="paragraph--small">
            Certeza mayor a <span>99,9%*</span>
          </p>
        </div>
        <div className="item">
          <div>
            <RectanguloDesktop className="desktop" />
            <Rectangulo className="mobile" />
          </div>
          <p className="paragraph--small">
            Sólo una <span>muestra de sangre</span>
          </p>
        </div>
        <div className="item">
          <div>
            <RectanguloDesktop2 className="desktop" />
            <Rectangulo3 className="mobile" />
          </div>
          <p className="paragraph--small">
            Resultados en <span>7 a 10 días hábiles.</span>
          </p>
        </div>
        <div>
          <p className="anomalias micro-text color--gris__3">
            *para las principales anomalias estudiadas
          </p>
        </div>
      </div>
    </motion.div>

    <div style={{ overflow: "hidden" }}>
      <motion.div
        initial={{ scale: 1.1 }}
        whileInView={{ scale: 1 }}
        viewport={{ once: true }}
      >
        <StaticImage
          src="../../assets/images/que-es/img-que-es.jpg"
          alt="Genesia"
          className="que-es__image"
        />
      </motion.div>
    </div>

    <div className="que-es__items">
      <div className="item">
        <div>
          <Rectangulo />
        </div>
        <p className="paragraph--small">
          A partir de las <span>9 semanas</span> de embarazo
        </p>
      </div>
      <div className="item">
        <div>
          <Rectangulo2 />
        </div>
        <p className="paragraph--small">
          Certeza mayor a <span>99,9%*</span>
        </p>
      </div>
      <div className="item">
        <div>
          <Rectangulo />
        </div>
        <p className="paragraph--small">
          Sólo una <span>muestra de sangre</span>
        </p>
      </div>
      <div className="item">
        <div>
          <Rectangulo3 />
        </div>
        <p className="paragraph--small">
          Resultados en <span>7 a 10 días hábiles.</span>
        </p>
      </div>
      <div>
        <p className="anomalias micro-text color--gris__3 text-center font-weight--500">
          *para las principales anomalías estudiadas
        </p>
      </div>
    </div>
  </StyledQueEs>
);

export default QueEs;
