import React from "react";

// Libraries
import styled from "styled-components";
import Slider from "react-slick";
import { motion } from "framer-motion";

// Components
import Container from "components/container";
import CarouselCardUser from "components/carousel-card-user";

// Icons
import Background from "assets/icons/call-to-action/call-to-action-background.svg";

// Arrow
import ArrowPrev from "assets/icons/testimonials/arrow-prev.inline.svg";
import ArrowNext from "assets/icons/testimonials/arrow-next.inline.svg";

// Utils
import { colors } from "utils/variables";
import breakpoints from "utils/breakpoints";
import Separator from "components/separator";

// Background
import Background2 from "assets/icons/testimonials/testimonials-background-lines.svg";

const data = require("../../assets/data/data.json");

const testimonials = data.testimonials?.length
  ? data.testimonials
      .filter((testimonial) => testimonial.quote.split(" ").length <= 30)
      .map((testimonial) => {
        const splittedName = testimonial.name.split(" ");
        const name =
          splittedName.length > 1
            ? `${splittedName[0]} ${splittedName[1].charAt(0)}.`
            : splittedName[0];
        return {
          name: name.toUpperCase(),
          quote: testimonial.quote.trim(),
        };
      })
      .sort(() => Math.random() - 0.5)
      .slice(0, 5)
      .slice(0, 5)
  : null;

const StyledTestimonials = styled.section`
  color: ${colors.blanco};
  text-align: center;
  padding: 40px 0 85px 0;
  background: url("${Background}"), url("${Background2}");
  background-repeat: no-repeat;
  background-position: center, center top;
  overflow-x: hidden;

  ${breakpoints.medium`
    background-size: 100% auto;
    padding-bottom: 269px;
    padding-top: 200px;
    margin-top: -180px;
  `}

  ${Container} {
    position: relative;
    z-index: 800;
  }

  .testimonials__content {
    .title {
      margin: 16px 0px;
    }

    .testiminials__intro {
      margin-bottom: 58px;
      ${breakpoints.medium`
        margin-bottom: 74px;
      `}
    }

    .testimonios {
      .slick-prev {
        padding: 18px 18px;
        background: ${colors.magenta};
        left: auto;
        right: 19%;
        top: auto;
        bottom: -56px;
        z-index: 9998;

        ${breakpoints.small`
          right: 11%;
        `}

        ${breakpoints.medium`
          left: auto;
          right: 0;
          top: auto;
          bottom: 75%;

        `}
      }

      .slick-next {
        padding: 18px 18px;
        background: ${colors.blanco};
        top: auto;
        right: 3%;

        ${breakpoints.medium`
          left: auto;
          right: -5%;
          bottom: 75%;
        `}
      }

      .slick-dots {
        display: flex !important;
        align-items: center;
        justify-content: flex-start;

        li {
          width: 32px;
          height: 10px;
          ${breakpoints.medium`
            width: 40px; 
          `}
          button {
            width: 32px;
            height: 10px;
            background: rgba(243, 172, 176, 0.1);
            border: 2px solid rgba(255, 255, 255, 0.272);
            box-sizing: border-box;
            backdrop-filter: blur(30.5415px);
            padding: 0;

            &:before {
              content: none;
            }
            ${breakpoints.medium`
              width: 40px; 
            `}
          }
        }

        .slick-active {
          margin-right: 35px;
          ${breakpoints.medium`
            margin-right: 45px;
          `}
          button {
            background-color: ${colors.magenta};
            border: 2px solid ${colors.magenta};
            width: 62px;
            height: 10px;
            ${breakpoints.medium`
              width: 80px;
            `}
          }
        }
      }
    }
  }
`;

const TestimonialsUser = () => {
  const settings = {
    fade: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  return (
    <StyledTestimonials id="experiencias">
      <Container small data-parallax="vertical">
        <motion.div
          className="testimonials__content"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <Separator colors={[colors.magenta, colors.blanco]} />
          <h3 className="title font-weight--500">Experiencias Compartidas</h3>
          <p className="testiminials__intro paragraph--medium">
            Conoce cómo Genesia ha impactado positivamente en la vida de
            nuestros pacientes
          </p>
          <div className="testimonios">
            <Slider {...settings}>
              {testimonials.map((testimonial) => (
                <CarouselCardUser key={testimonial.name} {...testimonial} />
              ))}
            </Slider>
          </div>
        </motion.div>
      </Container>
    </StyledTestimonials>
  );
};

export default TestimonialsUser;
