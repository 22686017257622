import React from "react";
import styled, { css } from "styled-components";

// Libraries
import PropTypes from "prop-types";

// Utils
import { Link } from "gatsby";

const SecondaryButtonStyles = css`
  display: inline-flex;
  align-items: center;
  padding: 17px 40px;
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  border: 2px solid;
  transition: all 0.3s ease;
`;

const ButtonStyles = css`
  position: relative;
  display: inline-block;
  padding: 17px 40px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  overflow: hidden;
  box-sizing: border-box;

  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor ? props.hoverBackgroundColor : ""};
    color: ${(props) => (props.hoverColor ? props.hoverColor : "")};

    &::after {
      transform: translateX(100%);
      opacity: 1;
    }
  }
`;

export const SecondaryButton = styled.button`
  display: inline-block;
  padding: 17px 22px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  border: 2px solid;
`;

export const PrimaryButtonExternalLink = styled.a`
  ${ButtonStyles}
`;

const StyledButtonLink = styled(Link)`
  ${ButtonStyles}
`;

export const PrimaryButton = styled.button`
  ${ButtonStyles}
`;

const ButtonLink = (props) => {
  const { children, className, to } = props;
  return (
    <StyledButtonLink className={className} to={to}>
      {children}
    </StyledButtonLink>
  );
};

export const SecondaryButtonLinkExternal = styled.a`
  ${SecondaryButtonStyles}
`;

ButtonLink.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

ButtonLink.defaultProps = {
  className: "",
};

export default ButtonLink;
