import React from "react";

// Libraries
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { motion } from "framer-motion";

// Variables
import breakpoint from "utils/breakpoints";
import { colors, gradients, urls } from "utils/variables";

// Icon
import Icon from "assets/icons/trayectoria/trayectoria.inline.svg";

// Components
import Container from "components/container";
import Separator from "components/separator";

const StyledTrayectoria = styled.section`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${colors.blanco};
  margin: 22px 0px 88px 0px;

  span {
    color: ${colors.rosa};
  }

  ${Container} {
    position: relative;
  }

  ${breakpoint.medium`
    flex-direction: row;
    margin: 137px 0px 135px 0px;
  `}

  .trayectoria--content {
    padding: 43px 17px 51px 17px;
    background: ${colors.azul};
    ${breakpoint.medium`
      background: ${gradients.blue};
      text-align: start;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0px 93px 0px 93px;

    `}

    .genesia {
      font-weight: 600;
    }

    .text {
      margin-top: 16px;
      margin-bottom: 40px;
      letter-spacing: -0.01em;
    }
    .contact__container {
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        a {
          color: ${colors.magenta};
          transition: none !important;
        }
        svg {
          * {
            stroke: ${colors.magenta};
          }
        }
      }

      ${breakpoint.medium`
        justify-content: start;
      `}

      .contact {
        color: ${colors.rosa};
        margin-right: 8px;
      }
    }
  }

  .trayectoria__separator {
    position: absolute;

    &--top {
      top: 45px;
      right: 0;
      margin: auto;

      ${breakpoint.medium`
        top: 88px;
        left: 0;
      `}
    }

    &--center {
      bottom: 54px;
      left: 60px;
    }

    &--bottom {
      top: 278px;
      left: 65px;

      ${breakpoint.medium`
        top: auto;
        bottom: 182px;
        left: 40%;
      `}
    }
  }
`;

const Trayectoria = () => (
  <StyledTrayectoria id="sobre-nosotros">
    <Container small data-parallax="vertical">
      <div className="row">
        <div className="col-12 col-md-6 pe-md-0">
          <StaticImage
            src="../../assets/images/trayectoria/img-aboutx4.png"
            alt="Genecia"
          />
        </div>
        <div className="col-12 col-md-6 ps-md-0">
          <div className="trayectoria--content">
            <motion.div
              initial={{ y: 64, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.6,
              }}
              viewport={{ once: true }}
            >
              <p className="micro-text genesia">GENESIA</p>
              <br />
              <h3>
                Más de <span>10 años</span>
                <br /> de trayectoria
                <br /> nos avalan
              </h3>
              <p className="paragraph--small text font-weight--500">
                Somos especialistas en medicina genómica, con foco en el área
                reproductiva, y compromiso con la calidez humana en cada uno de
                nuestros servicios.
              </p>
              <div className="contact__container font-weight--700">
                <a
                  href={urls.whatsapp ? urls.whatsapp : "#contact"}
                  target={urls.whatsapp ? "_blank" : "_self"}
                  rel="noreferrer"
                  className="contact button-small"
                >
                  Contactanos
                </a>
                <Icon />
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <Separator
        colors={[colors.azul, colors.magenta]}
        className="trayectoria__separator trayectoria__separator--top"
      />
      <Separator
        colors={[colors.azul, colors.magenta]}
        className="trayectoria__separator trayectoria__separator--center d-none d-md-flex"
      />
      <Separator
        colors={[colors.azul, colors.magenta]}
        className="trayectoria__separator trayectoria__separator--bottom"
      />
    </Container>
  </StyledTrayectoria>
);

export default Trayectoria;
