import React from "react";

// PropTypes
import PropTypes from "prop-types";

// Styled
import styled from "styled-components";
// Utils
import { colors } from "utils/variables";
import breakpoints from "utils/breakpoints";

const StyledTestimonialsCard = styled.div`
  height: 100%;
  background-color: ${colors.blanco};
  box-shadow: 0px 0.793533px 7.93533px rgba(109, 141, 173, 0.25);
  padding: 89px 45px 55px 45px;
  text-align: center;
  color: ${colors.azul};

  ${breakpoints.large`
    padding: 89px 30px 74px 30px;
    height: 100%;
  `}

  svg {
    display: inline-flex;
  }

  .title {
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: 600;
  }
`;

const TestimonialsCard = ({ icon, title, description, footer }) => (
  <StyledTestimonialsCard>
    {icon}
    <h4 className="title">{title}</h4>
    <p className="paragraph--small font-weight--500">{description}</p>
    <p className="paragraph--small">{footer}</p>
  </StyledTestimonialsCard>
);

TestimonialsCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TestimonialsCard;
