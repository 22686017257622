import React from "react";

// Libraries
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Slider from "react-slick";
import { motion } from "framer-motion";

// Components
import Container from "components/container";
import CarouselCard from "components/carousel-card";

// Icons
// import IconAcmg from "assets/icons/testimonials/icon-acmg.inline.svg"
// import IconAcog from "assets/icons/testimonials/icon-acog.inline.svg"
// import IconAshg from "assets/icons/testimonials/icon-ashg.inline.svg"
// import Maternal from "assets/icons/testimonials/logo-maternal.inline.svg"
import Background from "assets/icons/call-to-action/call-to-action-background.svg";

// Arrow
import ArrowPrev from "assets/icons/testimonials/arrow-prev.inline.svg";
import ArrowNext from "assets/icons/testimonials/arrow-next.inline.svg";

// Utils
import { colors } from "utils/variables";
import breakpoints from "utils/breakpoints";
import Separator from "components/separator";

// Background
import Background2 from "assets/icons/testimonials/testimonials-background-lines.svg";

const StyledTestimonials = styled.section`
  color: ${colors.blanco};
  text-align: center;
  padding: 40px 0 85px 0;
  background: url("${Background}"), url("${Background2}");
  background-repeat: no-repeat;
  background-position: center, center top;
  overflow-x: hidden;

  ${breakpoints.medium`
    background-size: 100% auto;
    padding-bottom: 269px;
    padding-top: 200px;
    margin-top: -180px;
  `}

  ${Container} {
    position: relative;
    z-index: 800;
  }

  .testimonials__content {
    .title {
      margin: 16px 0px;
    }

    .testiminials__intro {
      margin-bottom: 58px;
      ${breakpoints.medium`
        margin-bottom: 74px;
      `}
    }

    .testimonios {
      .slick-prev {
        padding: 18px 18px;
        background: ${colors.magenta};
        left: auto;
        right: 19%;
        top: auto;
        bottom: -56px;
        z-index: 9998;

        ${breakpoints.small`
          right: 11%;
        `}

        ${breakpoints.medium`
          left: auto;
          right: 0;
          top: auto;
          bottom: 75%;

        `}
      }

      .slick-next {
        padding: 18px 18px;
        background: ${colors.blanco};
        top: auto;
        right: 3%;

        ${breakpoints.medium`
          left: auto;
          right: -5%;
          bottom: 75%;
        `}
      }

      .slick-dots {
        display: flex !important;
        align-items: center;
        justify-content: flex-start;

        li {
          width: 32px;
          height: 10px;
          ${breakpoints.medium`
            width: 40px; 
          `}
          button {
            width: 32px;
            height: 10px;
            background: rgba(243, 172, 176, 0.1);
            border: 2px solid rgba(255, 255, 255, 0.272);
            box-sizing: border-box;
            backdrop-filter: blur(30.5415px);
            padding: 0;

            &:before {
              content: none;
            }
            ${breakpoints.medium`
              width: 40px; 
            `}
          }
        }

        .slick-active {
          margin-right: 35px;
          ${breakpoints.medium`
            margin-right: 45px;
          `}
          button {
            background-color: ${colors.magenta};
            border: 2px solid ${colors.magenta};
            width: 62px;
            height: 10px;
            ${breakpoints.medium`
              width: 80px;
            `}
          }
        }
      }
    }
  }
`;

const Testimonials = () => {
  const { logoAcmg, logoAcog, logoAshg, logoMaternal } = useStaticQuery(graphql`
    query {
      logoAcmg: file(relativePath: { eq: "testimonials/logo-acmg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 192, quality: 100)
        }
      }

      logoAcog: file(relativePath: { eq: "testimonials/logo-acog.png" }) {
        childImageSharp {
          gatsbyImageData(width: 210, quality: 100)
        }
      }

      logoAshg: file(relativePath: { eq: "testimonials/logo-ashg.png" }) {
        childImageSharp {
          gatsbyImageData(width: 192, quality: 100)
        }
      }

      logoMaternal: file(
        relativePath: { eq: "testimonials/logo-maternal.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 192, quality: 100)
        }
      }
    }
  `);

  const testimonios = [
    {
      testimonio:
        "Recomienda “informar a todas las mujeres embarazadas que el ADN Fetal es el método de screening más sensible para las aneuploidías tradicionalmente estudiadas”.",
      logo: logoAcmg,
      institucion: "American College of Medical Genetics",
    },
    {
      testimonio:
        "“Cualquier paciente puede elegir el análisis del ADN Fetal como una estrategia de screening para aneuplodías comunes, independientemente de su riesgo”",
      logo: logoAcog,
      institucion: "American College of Obstetricians and Gynecologists",
    },
    {
      testimonio:
        "“Diferentes escenarios son posibles, incluido el análisis del ADN Fetal como primera opción”",
      logo: logoAshg,
      institucion: "American Society of Human Genetics",
    },
    {
      testimonio:
        "“El siguiente protocolo es considerado apropiado actualmente: 1. Análisis del ADN Fetal como el test primario ofrecido a todas las mujeres embarazadas”",
      logo: logoMaternal,
      institucion: "Society for Maternal-Fetal Medicine",
    },
  ];

  const settings = {
    fade: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  return (
    <StyledTestimonials id="opiniones">
      <Container small data-parallax="vertical">
        <motion.div
          className="testimonials__content"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <Separator colors={[colors.magenta, colors.blanco]} />
          <h3 className="title font-weight--500">
            Respaldo de la comunidad médica
          </h3>
          <p className="testiminials__intro paragraph--medium">
            El test prenatal no invasivo o ADN Fetal se realiza de rutina en los
            países más desarrollados y está respaldado por las principales
            asociaciones internacionales de genética y reproducción:
          </p>
          <div className="testimonios">
            <Slider {...settings}>
              {testimonios.map((data) => (
                <CarouselCard key={data.institucion} {...data} />
              ))}
            </Slider>
          </div>
        </motion.div>
      </Container>
    </StyledTestimonials>
  );
};

export default Testimonials;
